import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import BigTextH3 from "src/components/global/typography/BigTextH3"
import MidText from "src/components/global/typography/MidText"
import SmallTitle from "src/components/global/typography/smallTitle.js"

import mediaQuery from "src/assets/styles/mediaQuery"
import Wrapper from "src/components/global/Wrapper"
import Divider from "src/components/global/Divider.js"
import ExternalBtn from "src/components/global/btn/ExternalBtn"
import colors from "src/assets/styles/colors"
const StyledHeaderImg = styled(Img)`
  width: 50%;
  height: 30vw;
  object-fit: cover;
  object-position: center;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 50%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 33.33%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: 50vw;
  }
`

const TopHeader = styled.div`
  display: flex;
  @media (max-width: ${mediaQuery.mobile}) {
    flex-direction: column;
  }
`

const HeaderPersonName = styled(BigTextH3)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
  }
`

const HeaderClaim = styled(BigTextH3)`
  align-self: flex-end;
  width: 66.66%;
  margin-bottom: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const BottomHeader = styled.div`
  display: flex;

  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
  }
`

const ContactCol = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 50%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 66.66%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const ContactColItem = styled.div`
  flex: 1;
  border-bottom: 2px solid ${colors.darkGrey};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 40px;
  margin-right: 20px;
  &:last-child {
    border-bottom: none;
    justify-content: flex-start;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-right: 0;
  }
  div {
    @media (max-width: ${mediaQuery.tablet}) {
      margin-top: 0px;
    }
  }
`

const PersonContact = props => (
  <Wrapper>
    <TopHeader>
      <HeaderClaim>
        <ReactMarkdown source={props.Claim} />
      </HeaderClaim>
    </TopHeader>

    {/* <TopRightHeader>
      <HeaderPersonName>{props.PersonName}</HeaderPersonName>
    </TopRightHeader> */}
    <SmallTitle>{props.PersonName}</SmallTitle>
    <BottomHeader>
      <ContactCol>
        <ContactColItem>
          <ExternalBtn
            hoverText={props.TelephoneHoverText}
            Mobile={props.TelephoneMobile}
            link={props.TelephoneLink}
          >
            {props.TelephoneMain}
          </ExternalBtn>
        </ContactColItem>
        <ContactColItem>
          <ExternalBtn
            marginTop="40px"
            hoverText={props.MailHoverText}
            Mobile={props.MailMobile}
            link={props.MailLink}
          >
            {props.MailMain}
          </ExternalBtn>
        </ContactColItem>
      </ContactCol>

      <StyledHeaderImg fluid={props.Img} />

      {/* <ExternalBtn
        marginTop="5px"
        hoverText={props.AddressHoverText}
        Mobile={props.AddressMobile}
        link={props.AddressLink}
      >
        {props.AddressMain}
      </ExternalBtn> */}
    </BottomHeader>
  </Wrapper>
)

export default PersonContact

import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import slugify from "slugify"
import { Link } from "gatsby"
import ArrowBig from "src/assets/svg/global/arrow-big.svg"

import BigTextH3 from "src/components/global/typography/BigTextH3"
import MidText from "src/components/global/typography/MidText"

import mediaQuery from "src/assets/styles/mediaQuery"
import Wrapper from "src/components/global/Wrapper"

import ExternalBtn from "src/components/global/btn/ExternalBtn"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"

const CompetancesWrapper = styled.div`
  width: 100%;
`

const CompetanceWrapper = styled(Link)`
  display: block;
  border-bottom: 2px solid ${colors.black};
  width: 100%;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  &:first-child {
    /* border-top: 2px solid ${colors.black}; */
  }
  &:last-child {
    border-bottom: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.mainColor};
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    z-index: -1;
  }

  @media (min-width: ${mediaQuery.tablet}) {
    &:hover::before {
      transform: translateY(0);
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;

  @media (min-width: ${mediaQuery.tablet}) {
    ${CompetanceWrapper}:hover & {
      transform: translateX(140px);
    }
  }
`

const StyledName = styled(BigTextH3)`
  margin-right: 70px;
`

const Number = styled.span`
  font-size: ${typographySizes.xl}px;
  color: ${colors.darkGrey};
  line-height: 1.1;
  width: 140px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
    width: 70px;
  }
`
const StyledArrow = styled(ArrowBig)`
  position: absolute;
  right: 0;
  &.leftArrow {
    left: 0;
    transform: translateX(-140px);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 50px;
  }
`

const SocialList = props => (
  <CompetancesWrapper>
    {props.data.map((competence, index) => (
      <CompetanceWrapper to={competence.link} key={index}>
        <ContentWrapper>
          <StyledArrow className="leftArrow" />
          <Number>0{index + 1}</Number>
          <StyledName>{competence.name}</StyledName>
          <StyledArrow className="rightArrow" />
        </ContentWrapper>
      </CompetanceWrapper>
    ))}
  </CompetancesWrapper>
)

export default SocialList

import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import noHangers from "src/components/global/fn/noHangers.js"

// import typographySizes from "src/assets/styles/typographySizes.js"
// import colors from "src/assets/styles/colors.js"
import SEO from "src/components/global/SEO.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import Wrapper from "src/components/global/Wrapper"
import MainBtn from "src/components/global/btn/MainBtn"
import ArrowLink from "src/components/global/ArrowLink.js"

import BigTextH3 from "src/components/global/typography/BigTextH3.js"
import MidText from "src/components/global/typography/MidText"
import BodyText from "src/components/global/typography/BodyText.js"

import SmallTitle from "src/components/global/typography/smallTitle.js"
import Map from "src/components/global/MapOne.js"

import Companies from "src/components/global/Companies/Companies"
import PersonContact from "src/components/global/PersonContact.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import NewBusinessSection from "src/components/global/NewBusinessSection.js"

import SocialList from "src/components/contact/SocialList.js"

const SocialMediaWrapper = styled.div`
  width: calc(100% + 40px);
  display: flex;
  position: relative;
  left: -20px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -20px;
  @media (max-width: ${mediaQuery.mobile}) {
    width: 100%;
  }
`

const RecruitmentTitle = styled(BigTextH3)`
  width: 66.66%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`

const DataTitle = styled(BigTextH3)`
  width: 100%;
`
const DataMid = styled(MidText)`
  width: 100%;
`

const DataSmaller = styled(BodyText)`
  width: 100%;
`

const RecruitmentBottom = styled.div`
  /* margin-left: calc(41.66% - 52px); */
  display: flex;
  flex-direction: column;
`

class KontaktLang extends React.Component {
  render() {
    const DataContact = this.props.data.strapiContactPageN

    const socialData = [
      {
        name: "Facebook",
        link: this.props.data.strapiSocialMediaLinks.Facebook,
      },
      {
        name: "Instagram",
        link: this.props.data.strapiSocialMediaLinks.Instagram,
      },
      {
        name: "Linkedin",
        link: this.props.data.strapiSocialMediaLinks.Linkedin,
      },
    ]

    return (
      <>
        <SEO
          title={this.props.data.strapiContactPageN.SEO.SEO_Title}
          description={this.props.data.strapiContactPageN.SEO.SEO_Description}
          image={
            this.props.data.strapiContactPageN.SEO.Seo_Img.localFile.publicURL
          }
        />
        <Navigation
          lang={this.props.pageContext.locale}
          langUrl={this.props.pageContext.langUrl}
          navBtnLink={this.props.data.strapiGlobal.Nav_btn_link}
          navBtnText={this.props.data.strapiGlobal.Nav_btn_text}
        />
        <PersonContact
          Img={DataContact.Person_Img.localFile.childImageSharp.fluid}
          PersonName={DataContact.Person_Name}
          Claim={noHangers(DataContact.Claim)}
          TelephoneHoverText={DataContact.Telephone.Hover}
          TelephoneLink={`tel:${DataContact.Telephone.Hover}`}
          TelephoneMain={DataContact.Telephone.Main}
          TelephoneMobile={DataContact.Telephone.Mobile}
          MailHoverText={DataContact.Mail.Hover}
          MailLink={`mailto:${DataContact.Mail.Hover}`}
          MailMain={DataContact.Mail.Main}
          MailMobile={DataContact.Mail.Mobile}
          AddressHoverText={DataContact.Address_.Hover}
          AddressLink={`${DataContact.Address_.Link}`}
          AddressMain={DataContact.Address_.Main}
          AddressMobile={DataContact.Address_.Mobile}
        />
        <NewBusinessSection
          claim={DataContact.New_business_Claim}
          title={DataContact.New_business_Title}
          aboutBtn={DataContact.About_Btn}
          briefBtn={DataContact.Brief_btn}
          onlyPl={this.props.pageContext.locale == "en" && true}
          lang={this.props.pageContext.locale}
        />

        {this.props.pageContext.locale !== "en" && (
          <Wrapper>
            <SmallTitle>{DataContact.Recruitment_Title}</SmallTitle>
            <RecruitmentTitle>
              {noHangers(DataContact.Recruitment_Claim)}
            </RecruitmentTitle>
            <RecruitmentBottom>
              <MainBtn
                // oneText
                marginTop="50px"
                hoverText={DataContact.Recruitment_Btn.Hover}
                Mobile={DataContact.Recruitment_Btn.Mobile}
                link={"/dolacz#positions"}
              >
                {DataContact.Recruitment_Btn.Main}
              </MainBtn>
            </RecruitmentBottom>
          </Wrapper>
        )}
        <Wrapper>
          <SmallTitle>{DataContact.Social_Title}</SmallTitle>
          <SocialList data={socialData} />
          {/* <SocialMediaWrapper>
            <ArrowLink
              external
              link={this.props.data.strapiSocialMediaLinks.Facebook}
            >
              facebook
            </ArrowLink>
            <ArrowLink
              external
              link={this.props.data.strapiSocialMediaLinks.Instagram}
            >
              instagram
            </ArrowLink>
            <ArrowLink
              external
              link={this.props.data.strapiSocialMediaLinks.Linkedin}
            >
              linkedin
            </ArrowLink>
          </SocialMediaWrapper> */}
        </Wrapper>

        <Wrapper>
          <SmallTitle>{DataContact.Company_details_Title}</SmallTitle>
          <DataTitle>
            {DataContact.Company_details_Name} <br />
            {DataContact.Company_details_Address} <br />
            {DataContact.Company_details_Nip}
            <br />
            {DataContact.Company_details_Krs}
            <br />
            {DataContact.Company_details_Regon}
          </DataTitle>
        </Wrapper>

        <Map lat={DataContact.Map_lat} lng={DataContact.Map_lng} />

        {/* <Companies lang={this.props.pageContext.locale} /> */}
        <Footer
          topM0
          lang={this.props.pageContext.locale}
          companyData={this.props.data.strapiContactPageN}
        />
      </>
    )
  }
}

export const KontaktQuery = graphql`
  query KontaktQuery($locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      Nav_btn_link
      Nav_btn_text
    }
    strapiSocialMediaLinks {
      Facebook
      Instagram
      Linkedin
    }
    strapiContactPageN(locale: { eq: $locale }) {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
      Map_lng
      Map_lat
      SEO {
        SEO_Title
        SEO_Description
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
      Address_ {
        Hover
        Main
        Mobile
        Link
      }
      Mail {
        Hover
        Main
        Mobile
      }
      New_business_Claim
      New_business_Title
      Person_Img {
        localFile {
          childImageSharp {
            fluid(
              maxWidth: 600
              quality: 90
              webpQuality: 100
              traceSVG: { background: "#ffffff", color: "#ffff00" }
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      Person_Name
      Telephone {
        Hover
        Main
        Mobile
      }
      Claim
      Social_Title
      About_Btn {
        Hover
        Main
        Mobile
      }
      Brief_btn {
        Hover
        Main
        Mobile
        Link
      }
      What_We_Do_Btn {
        Hover
        Main
        Mobile
      }
      Recruitment_Claim
      Recruitment_Title
      Recruitment_Btn {
        Hover
        Main
        Mobile
      }
    }
  }
`

export default KontaktLang

import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import ExternalBtn from "src/components/global/btn/ExternalBtn"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import BigTextH3 from "src/components/global/typography/BigTextH3.js"
import BodyText from "src/components/global/typography/BodyText.js"
import noHangers from "src/components/global/fn/noHangers.js"
import Swipeable from "react-swipeable"

const ImgWrapper = styled(Swipeable)`
  width: 50%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  height: 30vw;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: 50vw;
  }
`

const StyledImg = styled(Img)`
  width: 100%;
  flex-shrink: 0;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1); ;
`

const RightCol = styled.div`
  width: calc(41.66% + 52px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(66.66% + 20px);
    margin-left: 20px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
`

const Title = styled(BigTextH3)`
  margin-left: 52px;
  position: relative;
  top: -12px;
  margin-bottom: -12px;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-left: 0px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    top: 0px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
`

const Description = styled(BodyText)`
  margin-top: 20px;
  margin-left: 52px;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-left: 0px;
  }
`

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  opacity: 0;
  transform: translate(0, -100%);
  transition: all 0.5s;
  width: 100%;

  .disable {
    opacity: 0.4;
    cursor: not-allowed;
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    opacity: 1;
    transform: translate(0, -0%);
  }
`

const ArrowHead = styled.div`
  border: solid ${colors.darkGrey};
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 1px;
  transform: rotate(-45deg);
  transition: all 0.5s;
  height: 6px;
  width: 6px;
`

const ArrowHeadLeft = styled(ArrowHead)`
  transform: rotate(135deg) scale(1);
  transition: all 0.5s;
`

const ArrowHeadRight = styled(ArrowHead)`
  position: relative;
  left: -2px;
`

const ShowNext = styled.button`
  transition: all 0.5s;
  height: 40px;
  border: solid 1px ${colors.darkGrey};

  width: 40px;
  margin-top: 5px;
  margin-right: 5px;
  background-color: ${colors.white};
  cursor: pointer;
  &.nondisable:hover {
    /* transform: translateX(10px);
    transition: all 0.5s; */
  }
  &.nondisable:hover ${ArrowHeadRight} {
    transform: translateX(5px) rotate(-45deg);
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    &.nondisable:hover ${ArrowHeadRight} {
      transform: translateX(0px) rotate(-45deg);
      transition: all 0.5s;
    }
  }
`

const ShowPrev = styled.button`
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin-left: 5px;
  background-color: ${colors.white};
  border: solid 1px ${colors.darkGrey};

  cursor: pointer;

  &.nondisable:hover {
    /* transform: translateX(-10px);
    transition: all 0.5s; */
  }
  &.nondisable:hover ${ArrowHeadLeft} {
    transform: translateX(-5px) rotate(135deg);
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    &.nondisable:hover ${ArrowHeadLeft} {
      transform: translateX(0px) rotate(135deg);
      transition: all 0.5s;
    }
  }
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  position: relative;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }

  :hover ${ControlWrapper} {
    transform: translate(0, calc(0% - 0px));
    transition: all 0.5s;
    opacity: 1;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    overflow: visible;
  }
`

class Company extends React.Component {
  state = {
    x: 0,
    prevDisable: true,
    nextDisable: false,
  }
  changeSlideNext = () => {
    // Change x for slide transition
    this.state.x === -100 * (this.props.Gallery.length - 1)
      ? this.setState({
          x: -100 * (this.props.Gallery.length - 1),
        })
      : this.setState({
          x: this.state.x - 100,
        })

    this.state.x <= -100 * (this.props.Gallery.length - 2)
      ? this.setState({
          nextDisable: true,
        })
      : this.setState({
          nextDisable: false,
        })

    this.state.x <= 0
      ? this.setState({
          prevDisable: false,
        })
      : this.setState({
          prevDisable: true,
        })
  }

  changeSlidePrev = () => {
    this.state.x < 0
      ? this.setState({
          x: this.state.x + 100,
        })
      : this.setState({
          x: 0,
        })

    this.state.x >= -100
      ? this.setState({
          prevDisable: true,
        })
      : this.setState({
          prevDisable: false,
        })

    this.state.x < this.props.Gallery.length * -100
      ? this.setState({
          nextDisable: true,
        })
      : this.setState({
          nextDisable: false,
        })
  }
  swipingLeft = () => {
    this.changeSlideNext()
  }
  swipingRight = () => {
    this.changeSlidePrev()
  }
  render() {
    return (
      <>
        <Wrapper>
          {/* <Swipeable
            onSwipedLeft={this.swipingLeft}
            preventDefaultTouchmoveEvent={true}
            onSwipedRight={this.swipingRight}
            preventDefaultTouchmoveEvent={true}
          > */}
          <ImgWrapper
            onSwipedLeft={this.swipingLeft}
            preventDefaultTouchmoveEvent={true}
            onSwipedRight={this.swipingRight}
            preventDefaultTouchmoveEvent={true}
          >
            {this.props.Gallery.map((document, index) => (
              <>
                <StyledImg
                  key={index}
                  style={{ transform: `translateX(${this.state.x}%)` }}
                  fluid={document.localFile.childImageSharp.fluid}
                />
              </>
            ))}
            <ControlWrapper>
              <ShowPrev
                className={this.state.prevDisable ? "disable" : "nondisable"}
                onClick={this.changeSlidePrev}
              >
                <ArrowHeadLeft />
              </ShowPrev>
              <ShowNext
                className={this.state.nextDisable ? "disable" : "nondisable"}
                onClick={this.changeSlideNext}
              >
                <ArrowHeadRight />
              </ShowNext>
            </ControlWrapper>
          </ImgWrapper>
          {/* </Swipeable> */}
          <RightCol>
            <div>
              <Title> {this.props.Title}</Title>

              <Description>{noHangers(this.props.Description)}</Description>
              {/* <ExternalBtn
                // oneText
                hoverText={this.props.Hover}
                Mobile={this.props.Mobile}
                link={this.props.WWW_Link}
              > */}
              {/* {this.props.Main}
              </ExternalBtn> */}
            </div>
          </RightCol>
        </Wrapper>
      </>
    )
  }
}

export default Company

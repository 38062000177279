import React from "react"
import { StaticQuery, graphql } from "gatsby"

import styled from "styled-components"

import SmallTitle from "src/components/global/typography/smallTitle.js"

import Company from "src/components/global/Companies/Company"
import Wrapper from "src/components/global/Wrapper"

const CompaniesWrapper = styled.div``

class Companies extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query Companies {
            allStrapiCompanies {
              edges {
                node {
                  Description
                  Title
                  locale
                  Gallery {
                    localFile {
                      childImageSharp {
                        fluid(
                          maxWidth: 800
                          traceSVG: { background: "#ffffff", color: "#ffff00" }
                        ) {
                          ...GatsbyImageSharpFluid_tracedSVG
                        }
                      }
                    }
                  }
                  WWW_Btn {
                    Hover
                    Main
                    Mobile
                  }
                  WWW_Link
                }
              }
            }
          }
        `}
        render={data => (
          <Wrapper id="Firmy">
            <SmallTitle>
              {this.props.lang == "en" ? "teams" : "zespoły"}
            </SmallTitle>
            <CompaniesWrapper>
              {data.allStrapiCompanies.edges.map(
                (document, index) =>
                  this.props.lang == document.node.locale && (
                    <Company
                      Title={document.node.Title}
                      Description={document.node.Description}
                      Hover={document.node.WWW_Btn.Hover}
                      Main={document.node.WWW_Btn.Main}
                      Mobile={document.node.WWW_Btn.Mobile}
                      WWW_Link={document.node.WWW_Link}
                      Gallery={document.node.Gallery}
                    />
                  )
              )}
            </CompaniesWrapper>
          </Wrapper>
        )}
      />
    )
  }
}

export default Companies

import styled from "styled-components"
import distances from "src/assets/styles/distances.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import colors from "src/assets/styles/colors.js"

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.darkGrey};
`
export default Divider
